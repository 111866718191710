<template>
  <div>
    <Dialog
      :header="$t('roles.addNewRole')"
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '50vw' }"
    >
      <div class="grid grid-nogutter flex-column">
        <div class="field p-fluid col-12 md:col-8 lg:col-7">
          <InputText
            id="roleName"
            type="text"
            :class="{ 'p-invalid': v$.role.name.$invalid && submitted }"
            v-model.trim="v$.role.name.$model"
            aria-describedby="roleName-error"
            :placeholder="$t('roles.roleName') + ' *'"
          />
          <!-- <small
            v-if="
              (v$.role.name.$invalid && submitted) ||
              v$.role.name.$pending.$response
            "
            class="p-error"
            >{{
              v$.role.name.required.$message.replace(
                "[value]",
                $t("roles.roleName")
              )
            }}</small
          > -->
        </div>
        <ul
          v-if="v$.role.name.$error && submitted"
          class="p-error text-xs col-10 pl-3 mt-0"
        >
          <li v-for="(error, index) of v$.role.name.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("roles.roleName")) }}
          </li>
        </ul>
        <div class="field p-fluid">
          <!-- <MultiSelect
            :loading="loading"
            v-model="role.permissions"
            :options="permissions"
            optionLabel="name"
            :placeholder="$t('roles.selectPermissions') + ' *'"
            :filter="true"
            display="chip"
            :class="{ 'p-invalid': v$.role.permissions.$invalid && submitted }"
            :filterPlaceholder="$t('roles.searchPermissions')"
            :showToggleAll="false"
            class="permissions"
          >
          </MultiSelect> -->

          <PickList
            v-model="permissionsToShow"
            :class="{ 'p-invalid': v$.role.permissions.$invalid && submitted }"
            listStyle="height:342px"
            dataKey="id"
          >
            <template #sourceheader>
              {{ $t("roles.availablePermissions") }}
            </template>
            <template #targetheader>
              {{ $t("roles.selectedPermissions") }}
            </template>
            <template #movecontrolsstart> </template>
            <template #item="slotProps">
              {{ slotProps.item.name }}
            </template>
          </PickList>
          <!-- <small
            v-if="
              (v$.role.permissions.$invalid && submitted) ||
              v$.role.permissions.$pending.$response
            "
            class="p-error"
            >{{ $t("roles.missingPermissions") }}</small
          > -->
          <ul
            v-if="v$.role.permissions.$error && submitted"
            class="p-error text-xs col-10 pl-3 mt-0"
          >
            <li
              v-for="(error, index) of v$.role.permissions.$errors"
              :key="index"
            >
              {{
                error.$message.replace(
                  "[value]",
                  $t("roles.missingPermissions")
                )
              }}
            </li>
          </ul>
        </div>
        <div class="col py-0">
          <span>* {{ $t("global.mandatoryFields") }}</span>
        </div>

        <ProgressSpinner
          v-if="loading"
          class="absolute top-50 left-50 w-8rem h-8rem"
          style="transform: translate(-50%, -50%)"
        />
      </div>
      <template #footer>
        <Button
          :label="roleId == null ? $t('global.create') : $t('global.update')"
          class="p-button-success"
          @click="create"
          autofocus
          iconPos="right"
          :loading="loadingButton"
        />
        <Button
          :label="$t('global.cancel')"
          @click="close"
          class="p-button-danger"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import { required } from "@/utilities/i18n-validators";
// import { _ } from "lodash"

export default {
  name: "Add role",
  props: ["display", "roleId", "roleName"],
  emits: ["closeAddRoleDialog"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      role: {
        name: "",
        permissions: [],
      },
      originalPermissions: [],
      permissionsToShow: [[], []],
      loading: true,
      submitted: false,
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters(["permissions", "getRole"]),
  },
  validations() {
    return {
      role: {
        name: {
          required,
        },
        permissions: {
          required,
        },
      },
    };
  },
  watch: {
    async display(val) {
      this.viewDialog = val;
      if (val) {
        this.loading = true;
        await this.$store.dispatch("permissions");
        await this.$store.dispatch("adminPermissions");
        let tempPermissions = [...this.permissions];
        if (this.roleId != null) {
          await this.$store.dispatch("rolePermissions", this.roleName);

          Object.assign(this.role, this.getRole(this.roleId));
          Object.assign(this.originalPermissions, this.role.permissions);
          console.log(this.role);
          console.log(this.originalPermissions);
          tempPermissions = tempPermissions.filter((el) => {
            return !this.role.permissions.find((el1) => el1.id == el.id);
          });
        }
        this.permissionsToShow = [
          [...tempPermissions],
          [...this.role.permissions],
        ];
        this.loading = false;
      }
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
  },

  methods: {
    close(success = false) {
      this.$emit("closeAddRoleDialog", success);
      this.role = {
        name: "",
        permissions: [],
      };
      this.permissionsToShow = [[], []];
      this.submitted = false;
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      this.role.permissions = [...this.permissionsToShow[1]];
      if (result) {
        this.loadingButton = true;
        if (this.roleId == null) {
          try {
            await this.$store.dispatch("createRole", this.role.name).then(() => {
              this.$action("role-create");
            })

            await this.$store.dispatch("addPermissionToRole", this.role);
            this.$notification(
              this.$t("roles.addNewRole"),
              this.$t("roles.roleCreated")
            );
            this.$notification(
              this.$t("roles.addNewRole"),
              this.$t("roles.reloadTableMessage"),
              "info"
            );

            this.close(true);
            this.loadingButton = false;
          } catch (err) {
            // this.$notification(
            //   this.$t("roles.addNewRole"),
            //   this.$t("global.errorMessage"),
            //   "error"
            // );
            this.loadingButton = false;
          }
        } else {
          this.loadingButton = true;
          const deletedPermissions = this.originalPermissions.filter(
            (el) =>
              !this.role.permissions.some((el1) => {
                return el1.id === el.id;
              })
          );

          const newPermissions = this.role.permissions.filter(
            (el) =>
              !this.originalPermissions.some((el1) => {
                return el1.id === el.id;
              })
          );
          try {
            await this.$store
              .dispatch("updateRoleName", {
                originalName: this.roleName,
                newName: this.role.name,
              })
              .then(async () => {
                if (newPermissions.length > 0) {
                  await this.$store.dispatch("addPermissionToRole", {
                    name: this.role.name,
                    permissions: newPermissions,
                  });
                }

                if (deletedPermissions.length > 0) {
                  await this.$store.dispatch("deletePermissionFromRole", {
                    name: this.role.name,
                    permissions: deletedPermissions,
                  });
                }

                this.$notification(
                  this.$t("roles.updateRole"),
                  this.$t("roles.roleUpdate")
                );

                this.$notification(
                  this.$t("roles.updateRole"),
                  this.$t("roles.reloadTableMessage"),
                  "info"
                );
                this.close(true);
                this.$action("role-update");
                this.loadingButton = false;
              });
          } catch (err) {
            // this.$notification(
            //   this.$t("roles.updateRole"),
            //   this.$t("global.errorMessage"),
            //   "error"
            // );
            this.loadingButton = false;
          }
        }
      }
    },
  },
};
</script>
