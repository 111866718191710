<template>
  <AddRole
    :display="addEditRoleVisible"
    @closeAddRoleDialog="closeAddRole"
    :roleId="roleId"
    :roleName="roleName"
  ></AddRole>
  <div>
    <Card>
      <template #content>
        <div class="grid">
          <div class="col-3 md:col-4">
            <h4>{{ $t("roles.title") }}</h4>
          </div>
           <div class="col-4 text-center md:col-6 md:text-right">
          <Button v-if="!isMobile" :label="$t('global.export')" @click="exportCSV"></Button>
        </div>
          <div class="col-5 text-center md:col-2 md:text-right">
            <Button :label="$t('roles.addRole')" @click="openRole" />
          </div>
        </div>
        <DataTable
          :value="roles"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :filters="filters"
          ref="tableRoles"
          showGridlines
          class="p-datatable-roles"
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, 50]"
          :currentPageReportTemplate="$tableString"
          removableSort
          sortField="creationDate"
          :sortOrder="-1"
        >
          <template #header>
            <div>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  :placeholder="$t('global.search')"
                />
              </span>
            </div>
          </template>
          <template #empty>
            {{ $t("roles.noRolesFound") }}
          </template>
          <!-- <Column
            field="creationDate"
            :header="$t('roles.creationDate')"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.creationDate }}
            </template>
          </Column> -->
          <Column
            field="name"
            :header="$t('roles.roleName')"
            filterMatchMode="contains"
            
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <!-- <Column
            field="permissions"
            :header="$t('roles.permissions')"
            style="width: 20%"
          >
            <template #body="{ data }">
              <div v-for="(perm, index) in data.permissions" :key="index">
                <Chip :label="perm.name" style="font-size: 11px"></Chip>
              </div>
            </template>
          </Column> -->
          <!-- <Column field="status" :header="$t('global.status')" :sortable="true">
            <template #body="{ data }">
              {{ translatedStatus(data.status) }}
            </template>
          </Column> -->
          <Column
            field="id"
            :header="$t('roles.roleId')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span class="text-right">{{ data.id }}</span>
            </template>
          </Column>
          <Column field="Actions" :header="$t('global.actions')" :exportable="false">
            <template #body="{ data }">
              <!-- <Button icon="pi pi-check" class="p-button-rounded p-button-text" /> -->
              <div class="text-center">
                <Button
                  :label="$t('global.edit')"
                  class="p-button-success"
                  @click="editRole(data.id, data.name)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <!-- <TablePaginator :rows="10" :totalRecords="roles.length" type="role"></TablePaginator> -->
      </template>
    </Card>
  </div>
</template>

<script>
import AddRole from "./CreateEditRole.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
// import { onMounted } from 'vue';
import { mapGetters } from "vuex";
// import TablePaginator from "../TablePaginator.vue";

export default {
  components: {
    AddRole,
    // TablePaginator
},
  data() {
    return {
      addEditRoleVisible: false,
      loading: true,
      roleId: null,
      roleName: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    };
  },
  //{{$t('global.showing')}} {first} to {last} of {totalRecords} entries"
  computed: {
    ...mapGetters(["roles","translatedStatus","isMobile"]),
  },
  async mounted() {
    this.getRolesInfo();
    this.$action("roles-page-view");
  },
  methods: {
    exportCSV() {
      this.$refs.tableRoles.exportCSV();
      this.$action("export-roles");
    },
    openRole() {
      this.addEditRoleVisible = true;
    },
    closeAddRole(value) {
      if (value == true) {
        console.log("RUN ROLESSS");
        this.loading = true;
        this.getRolesInfo();
      }
      this.addEditRoleVisible = false;
      this.roleId = null;
      this.roleName = null;
    },
    editRole(id, name) {
      this.roleId = id;
      this.roleName = name;
      this.openRole();
    },
    async getRolesInfo() {
      await this.$store.dispatch("roles");
      // await Promise.all(
      //   this.$store.getters.roles.map(async (el) => {
      //     await this.$store.dispatch("rolePermissions", el.name);
      //   })
      // );
      this.loading = false;
    },
  },
};
</script>
